import * as localForage from 'localforage';
import ReactDOM from 'react-dom';
import CustomToasters from '../components/CustomToasters';

const Logout = async() => {
    
    const user = await localForage.getItem('user')
    localForage.clear().then(res => window.location.assign('/'))
}

const getQueryString = (query) => {
    return Object.entries(query).map(entry => entry[0]+'='+entry[1]).join('&')
}

const compareJSON = (objA, objB) => {
    return JSON.stringify(objA) === JSON.stringify(objB)
}

const emptyJSON = (json) => {
    return JSON.stringify(json) === '{}'
}

const getDate = (dateInMS, withTime=false) => {
    if(!dateInMS) return
    const timeOptions = {hour: '2-digit', minute: '2-digit'}
    return new Date(dateInMS).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year:'numeric', ...(withTime && timeOptions)}).split('/').join('-')
}

const booleanHasAnyValue = (boolean) => {
    return typeof(boolean) === 'boolean'
}


const hexTohsl = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r = r/255;
    g = g/255;
    b = b/255;
    
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return { h,s,l }
}

const getHoverColor = (hex) => { 
    const { h,s,l } = hexTohsl(hex)
    return `hsl(${h},${s}%,${l+10}%)` 
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const capitalizeAll = (string) => {
      const Arr = string.split(" ")
      let result = []
      Arr.map((value, index)=>(
          result.push(value.charAt(0).toUpperCase() + value.slice(1))
      ))
    return result.join(' ')
  }


  function filterByValue(array, value) {
    return array.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  const showToaster = ({position, title, message, severity, delay}) => {
    const notifications = [{title, message, severity, progress: 0}]
    ReactDOM.render(<CustomToasters position={position} notifications={notifications} delay = {delay}/>, document.getElementById('Toaster'))
}


  function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  function getRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

function getRandomColor(firstName) {
    var letters = '0123456789ABCDEF';
    var color = '#';
    
    
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export {
    Logout,
    getRandomColor,
    getRandomString,
    getNumberWithOrdinal,
    filterByValue,
    hexTohsl,
    capitalizeAll,
    capitalize,
    getHoverColor,
    getQueryString,
    compareJSON,
    emptyJSON,
    getDate,
    booleanHasAnyValue,
    showToaster
}