import axios from 'axios'
import { Handlers, utils } from '../utils'
import env from '../config'
const service  = '/v4/'

const Login              = async({query}) => await axios.get(env.API_URL+service+'user/walletlyBusiness?'+utils.getQueryString(query), {headers: {"content-type": "text/json", "id" : query.idKey}})
const GetPublicPass      = async ({query}) => await axios.get(env.API_URL+service+'pass/public?'+utils.getQueryString(query))
const GetPublicBrand     = async ({query}) => await axios.get(env.API_URL+service+'brand?'+utils.getQueryString(query))
const GetFlows           = async ({payload}) => await axios.post(env.API_URL+service + 'transaction/getFlows', payload, {headers : { 'Accept-Encoding': 'gzip'}})
const BusinessCheckout   = async ({payload}) => { console.log('businessCalled',payload) ; return await axios.post(env.API_URL+service + 'transaction/businessCheckout', payload)  }
const SingleLoyalitySelf = async ({payload}) => await axios.post(env.API_URL+service + 'transaction/singleLoyalitySelf', payload)
const Phone              = async ({query}) => await axios.get(env.API_URL+service + 'pass/phone?'+utils.getQueryString(query))
const GetQueriedGrowthtool = async ({query}) => await axios.get(env.API_URL+service  + 'growthtool/public?'+utils.getQueryString(query))

const BusinessService = {
    Login                : Handlers.Services(Login),
    GetPublicPass        : Handlers.Services(GetPublicPass),
    GetFlows             : Handlers.Services(GetFlows),
    BusinessCheckout     : Handlers.Services(BusinessCheckout),
    SingleLoyalitySelf   : Handlers.Services(SingleLoyalitySelf),
    GetPublicBrand       : Handlers.Services(GetPublicBrand),
    Phone                : Handlers.Services(Phone),
    GetQueriedGrowthtool : Handlers.Services(GetQueriedGrowthtool)
}

export default BusinessService
