import React from 'react'
import Modal from 'react-bootstrap/Modal';

export default function CustomModal({component, open, onClose, minWidth}) {

    return (
      <div className='modal'>
        <Modal show={open} onHide={onClose}>
          {component}
        </Modal>
      </div>
    );
}
