import * as yup from 'yup';

const LoginVld = yup.object().shape({
    Email : yup.string().email().required('Enter valid email address')
})


export {
    LoginVld
}
