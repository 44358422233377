import React, { Component } from "react";

import * as localForage from 'localforage'
import PngIcons from "../assets/images/pngIcons";


const UserContext = React.createContext();

class UserContextComponent extends Component {
  state = {loader: true}

  getActiveBrand = () => {
    const param = window.location.pathname.split('/')[1]
    if(param.length > 10) return param
  }
  
  componentDidMount = async () => {
    const user = await localForage.getItem('user')
    this.setState({...user, loader : false})
  }

  updateUser = (updates, permissions) => {
    delete this.state.loader;
    const updatedUser = {...this.state, ...updates}
    this.setState(updatedUser)
    //console.log('updates ', updates);
    localForage.setItem('user', updatedUser)
  }



  render() {
    return (
      this.state.loader ? 
      <div className="Loader middle"><img height={40} width={40} src={PngIcons.Loader} alt="" /></div>
      :
      <UserContext.Provider value={{...this.state, updateUser: this.updateUser}}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export { UserContext, UserContextComponent }