const PngIcons = {
    Scanner: '/assets/img/Vector.png',
    Keypad: '/assets/img/code.png',
    Phone: '/assets/img/phone.png',
    Email: '/assets/img/email.png',
    Qr: '/assets/img/qr.png',
    Settings: '/assets//img/settings.png',
    Bell: '/assets/img/power-off.png',
    Loader: '/assets/img/loader.gif',
    Customer: '/assets/img/customer.png',
    CustomerNotified: '/assets/img/Customer-Detail-notified.png',
    CloseRounded: '/assets/img/CloseRounded.png',
    CrossIcon: '/assets/img/crossIcon.png',
    Reward: '/assets/img/reward.png',
    Reward2:'/assets/img/reward2.svg',
    Bell2:'/assets/img/bell2.svg',
    ScannerB : '/assets/img/scanr.png',
    ScannerW : '/assets/img/scanner3.svg',
    Refresh : '/assets/img/refresh.png',
    Man : '/assets/img/man.png',
    LoginW: '/assets/img/login-white.svg',
    LoginW2: '/assets/img/loginwhite.png',
    PowerWhite: '/assets/img/powerwhite.svg',
    RewardWhite: '/assets/img/rewardwhite.svg',
    image: '/assets/img/image.png'

}

export default PngIcons;