import './customer.scss'
import QrComponent from '../../components/QrComponent'
import CustomerComponent from '../../components/CustomerComponent'
import { useState, useEffect, useContext } from 'react'
import PngIcons from '../../assets/images/pngIcons'
import PhoneComponent from '../../components/PhoneComponent'
import EmailComponent from '../../components/EmailComponent'
import localforage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { utils } from '../../utils'
import { UserContext } from '../../context/User.context'


export default function CustomerPage() {

  const user = useContext(UserContext)

  


  const selectionType = [
    {
      title: 'Scanner',
      description: 'Scan customer qr code to take action.',
      component: <QrComponent />,
      image: PngIcons.Scanner
    },
    {
      title: 'Phone Number',
      description: 'Enter customer phone number to take action.',
      image: PngIcons.Phone,
      component: <PhoneComponent />
    },
    {
      title: 'Customer Email',
      description: 'Enter customer email to take action.',
      image: PngIcons.Email,
      component: <EmailComponent />
    }
  ]


const [media, setMedia] = useState(false);
const [reward, setReward] = useState(false);



  const [selectedComponent, setSelectedComponent] = useState(selectionType[0])

  const handleSelectFuc = selection => {
    setSelectedComponent(selection)
  }

  function onClickHandler() {
    utils.Logout();
  }
  const history = useNavigate();

//   const x = window.matchMedia("(max-width: 700px)")

// if(x.matches){
//   setMedia(true);
// }

// useEffect(() => {
  
// }, [x,media])

  useEffect(() => {

    checkFlows();

  }, [])
  

  const checkFlows = async ()=>{
    let check = await localforage.getItem("flows");
    console.log("Check Flows",check);
    if(!check){
      // console.log("value",check)
      setReward(true);
    }
    else{
      setReward(false);
    }
    console.log("reward",reward);
  }

  return (
    <div className='w-95 main-box-res' style={{ height: '100vh' , margin : '0 auto ' }} >
      <div style={{ height: '15%' , display : 'flex' , flexDirection : 'column',justifyContent : 'center'}} className='header-bg-res res-white res-padding'>
        <div className='body-font res-white'>Logged in by:</div>
        <div style={{display : 'flex' , justifyContent : 'space-between'}}>
          <h5 className='capitalize fs-24 res-white' style={{fontWeight : '600'}}>{user && user.firstName + ' ' + user.lastName}</h5>

          <div style={{display : 'flex'}}>
          
          {/* {!reward &&(
          <div style={{marginRight : '8px'}}> 
          <img
          // className='res-white '
          src={PngIcons.RewardWhite}
          className='gift-white'
          alt=''
          width= '28px'
          style={{cursor : 'pointer'}}
          height = '28px'
          onClick={() => history('/readflows')}
        />
          <img
          // className='res-white '
          src={PngIcons.Reward}
          alt=''
          className='gift-black'
          width= '28px'
          style={{cursor : 'pointer'}}
          height = '28px'
          onClick={() => history('/readflows')}
        />
        
        </div>)
        } */}
          <div>
          <img
            className='login-black'
            src={PngIcons.Bell}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          />
          <img
            className='login-white'
            src={PngIcons.PowerWhite}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          />

        </div>
       
          </div>
        </div>
       
      </div>


   
      <div style={{ height: '85%' }}>
        <div className=' d-flex justify-content-between customer responsive-boxes'>
          {selectionType.map(selection => (
            <div
              onClick={() => handleSelectFuc(selection)}
              className={`cardBox cp d-flex ${selectedComponent.title ===
                selection.title && 'selected p-auto'}`}
            >
              <div className='mr-15 middle'>
                <img
                  src={selection.image}
                  alt=''
                  height={20}
                  width={20}
                  className='icon'
                ></img>
              </div>
              <div>
                <div className='scanner'>{selection.title}</div>
                <div className='sub-text'>{selection.description}</div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <h1>{selectedComponent.component}</h1>
        </div>
      </div>
    




    </div>





  )
}
