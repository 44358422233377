import React, { Component } from "react";
import * as localForage from 'localforage'
import BrandService from "../services/Business.service";

const BrandContext = React.createContext();

class BrandContextComponent extends Component {
  state = {loader: true}

  componentDidMount = async () => {
      const brands = await localForage.getItem('brands')
      this.setState({loader: false, ...brands})
  }

  updateBrands = (updates) => {
    localForage.setItem('brands', {...this.state, ...updates}) 
    this.setState({...this.state, ...updates})
  }

  render() {
    return (
      <>
      {this.state.loader && <>Loading</>}
      {this.state.error && <div className="middle"><div>Not found</div></div>}
      {
        !this.state.loader && !this.state.error &&
        <BrandContext.Provider value={{...this.state, updateBrands: this.updateBrands}}>
         {this.props.children}
        </BrandContext.Provider>
      }
      </>
    )
  }
}

export { BrandContext, BrandContextComponent }