import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react';
import {Navigate, Route} from 'react-router-dom';

import {UserContext} from '../context/User.context'
import {BrandContext} from '../context/Brand.context'


function CentralGuard({children}){

    const user = useContext(UserContext)
    //console.log('user ', user);

    const checkuser = user.email ? true : false

    //console.log('check user ', checkuser)


    if(!checkuser && window.location.pathname != "/"){
        //console.log('1')
       return <Navigate replace to="/" />
    }
    else if(user.selectedBrand && window.location.pathname != "/scanner"){
        //console.log('2')
       return <Navigate to = '/scanner' replace />
    }
    else{
        //console.log('3')
        return children
    }
}

export {
    CentralGuard,
}