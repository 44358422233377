const hideKeyboard = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
        <path d="M5.25 9.5H7.25V11.5H5.25V9.5Z" fill="white" />
        <path d="M10.75 9.5H8.75V11.5H10.75V9.5Z" fill="white" />
        <path d="M12.25 9.5H14.25V11.5H12.25V9.5Z" fill="white" />
        <path d="M21.25 9.5H19.25V11.5H21.25V9.5Z" fill="white" />
        <path d="M26.25 9.5H28.25V11.5H26.25V9.5Z" fill="white" />
        <path d="M24.75 9.5H22.75V11.5H24.75V9.5Z" fill="white" />
        <path d="M15.75 9.5H17.75V11.5H15.75V9.5Z" fill="white" />
        <path d="M7.25 12.9H5.25V14.9H7.25V12.9Z" fill="white" />
        <path d="M8.75 12.9H10.75V14.9H8.75V12.9Z" fill="white" />
        <path d="M14.25 12.9H12.25V14.9H14.25V12.9Z" fill="white" />
        <path d="M19.25 12.9H21.25V14.9H19.25V12.9Z" fill="white" />
        <path d="M28.25 12.9H26.25V14.9H28.25V12.9Z" fill="white" />
        <path d="M22.75 12.9H24.75V14.9H22.75V12.9Z" fill="white" />
        <path d="M17.75 12.9H15.75V14.9H17.75V12.9Z" fill="white" />
        <path d="M5.25 16.5H7.25V18.5H5.25V16.5Z" fill="white" />
        <path d="M24.75 16.5H8.75V18.5H24.75V16.5Z" fill="white" />
        <path d="M26.25 16.5H28.25V18.5H26.25V16.5Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 6C2.19775 6 1.75 6.44769 1.75 7V21C1.75 21.5523 2.19775 22 2.75 22H30.75C31.3022 22 31.75 21.5523 31.75 21V7C31.75 6.44769 31.3022 6 30.75 6H2.75ZM3.25 7.5V20.5H30.25V7.5H3.25Z" fill="white" />
        <path d="M16.75 29.1462L12.4751 24.9605L11.5498 25.8667L16.752 30.9605L21.9497 25.8667L21.0244 24.9605L16.75 29.1462Z" fill="white" />
    </svg>

}
const Keyboard = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
    <path d="M5 11.5H7V13.5H5V11.5Z" fill="#32353E"/>
    <path d="M10.5 11.5H8.5V13.5H10.5V11.5Z" fill="#32353E"/>
    <path d="M12 11.5H14V13.5H12V11.5Z" fill="#32353E"/>
    <path d="M21 11.5H19V13.5H21V11.5Z" fill="#32353E"/>
    <path d="M26 11.5H28V13.5H26V11.5Z" fill="#32353E"/>
    <path d="M24.5 11.5H22.5V13.5H24.5V11.5Z" fill="#32353E"/>
    <path d="M15.5 11.5H17.5V13.5H15.5V11.5Z" fill="#32353E"/>
    <path d="M7 14.9H5V16.9H7V14.9Z" fill="#32353E"/>
    <path d="M8.5 14.9H10.5V16.9H8.5V14.9Z" fill="#32353E"/>
    <path d="M14 14.9H12V16.9H14V14.9Z" fill="#32353E"/>
    <path d="M19 14.9H21V16.9H19V14.9Z" fill="#32353E"/>
    <path d="M28 14.9H26V16.9H28V14.9Z" fill="#32353E"/>
    <path d="M22.5 14.9H24.5V16.9H22.5V14.9Z" fill="#32353E"/>
    <path d="M17.5 14.9H15.5V16.9H17.5V14.9Z" fill="#32353E"/>
    <path d="M5 18.5H7V20.5H5V18.5Z" fill="#32353E"/>
    <path d="M24.5 18.5H8.5V20.5H24.5V18.5Z" fill="#32353E"/>
    <path d="M26 18.5H28V20.5H26V18.5Z" fill="#32353E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 8C1.94775 8 1.5 8.44769 1.5 9V23C1.5 23.5523 1.94775 24 2.5 24H30.5C31.0522 24 31.5 23.5523 31.5 23V9C31.5 8.44769 31.0522 8 30.5 8H2.5ZM3 9.5V22.5H30V9.5H3Z" fill="#32353E"/>
  </svg>
}
const deleteOne = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21" fill="none">
        <path d="M12.7423 7.58841C12.3978 7.24381 12.3978 6.68516 12.7423 6.34056C13.087 5.99596 13.6457 5.99596 13.9902 6.34056L16.9019 9.25223L19.8135 6.34056C20.158 5.99596 20.7167 5.99596 21.0614 6.34056C21.4059 6.68509 21.4059 7.24381 21.0614 7.58841L18.1496 10.5L21.0614 13.4117C21.4059 13.7562 21.4059 14.3149 21.0614 14.6594C20.7167 15.004 20.158 15.004 19.8135 14.6594L16.9019 11.7478L13.9902 14.6594C13.6457 15.004 13.087 15.004 12.7425 14.6594C12.3978 14.3148 12.3978 13.7562 12.7425 13.4116L15.6541 10.5001L12.7423 7.58841Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10349 1.53372L0.137207 10.5L9.10349 19.4663C9.7654 20.1282 10.6631 20.5 11.5992 20.5H23.6666C25.6159 20.5 27.196 18.9198 27.196 16.9706V4.02941C27.196 2.08016 25.6159 0.5 23.6666 0.5H11.5992C10.6631 0.5 9.7654 0.871884 9.10349 1.53372ZM23.6666 18.9706H11.5992C11.0687 18.9706 10.56 18.7599 10.1849 18.3848L2.30015 10.5L10.1849 2.61519C10.56 2.24015 11.0687 2.0294 11.5992 2.0294H23.6666C24.7711 2.0294 25.6666 2.92482 25.6666 4.02941V16.9706C25.6666 18.0752 24.7711 18.9706 23.6666 18.9706Z" fill="black" />
    </svg>

}

const home = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00022 6C6.5525 6 7.00022 6.44772 7.00022 7V15.2C7.00022 16.0566 7.001 16.6389 7.03777 17.089C7.07359 17.5274 7.13852 17.7516 7.21821 17.908C7.40995 18.2843 7.71591 18.5903 8.09224 18.782C8.24864 18.8617 8.47284 18.9266 8.91126 18.9624C9.36135 18.9992 9.94364 19 10.8002 19H13.2002C14.0568 19 14.6391 18.9992 15.0892 18.9624C15.5276 18.9266 15.7518 18.8617 15.9082 18.782C16.2845 18.5903 16.5905 18.2843 16.7822 17.908C16.8619 17.7516 16.9268 17.5274 16.9627 17.089C16.9994 16.6389 17.0002 16.0566 17.0002 15.2V7C17.0002 6.44772 17.4479 6 18.0002 6C18.5525 6 19.0002 6.44772 19.0002 7V15.2413C19.0002 16.0463 19.0002 16.7106 18.956 17.2518C18.9101 17.8139 18.8115 18.3306 18.5642 18.816C18.1808 19.5686 17.5688 20.1805 16.8162 20.564C16.3308 20.8113 15.8141 20.9099 15.252 20.9558C14.7108 21 14.0465 21 13.2415 21H10.7589C9.95396 21 9.28959 21 8.74839 20.9558C8.1863 20.9099 7.66959 20.8113 7.18426 20.564C6.43161 20.1805 5.81969 19.5686 5.43619 18.816C5.1889 18.3306 5.09034 17.8139 5.04441 17.2518C5.0002 16.7106 5.00021 16.0463 5.00022 15.2413L5.00022 7C5.00022 6.44772 5.44793 6 6.00022 6Z" fill="#1059FA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 15C11.4479 15 11.0002 15.4477 11.0002 16V19H9.00022V16C9.00022 14.3431 10.3434 13 12.0002 13C13.6571 13 15.0002 14.3431 15.0002 16V19H13.0002V16C13.0002 15.4477 12.5525 15 12.0002 15Z" fill="#1059FA" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.10579 8.44721C3.35278 8.94119 3.95345 9.14142 4.44743 8.89443L12.0002 5.11803L19.553 8.89443C20.047 9.14142 20.6477 8.94119 20.8946 8.44721C21.1416 7.95323 20.9414 7.35256 20.4474 7.10557L12.4474 3.10557C12.1659 2.96481 11.8345 2.96481 11.553 3.10557L3.55301 7.10557C3.05903 7.35256 2.8588 7.95323 3.10579 8.44721Z" fill="#1059FA" />
    </svg>

}


const SvgIcons = {
    hideKeyboard,
    deleteOne,
    home,
    Keyboard
}

export default SvgIcons