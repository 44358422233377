import * as yup from 'yup';

const LoginVld = yup.object().shape({
    userId : yup.string().required('User Id is required').min(6).max(6)
})


export {
    LoginVld
}
