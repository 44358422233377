// CSS (do not change the orders)
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/global.scss'

// Pages
import Login from './pages/auth/LoginPage'
import CustomerPage from './pages/auth/CustomerPage';
import FlowData from './pages/FlowData';
import Spend from './pages/Spend';
import ReadFlowData from './pages/ReadFlowData';
import Deal from './pages/Deal';
import Brand from './pages/Brand';
import { CentralGuard } from './utils/RouteGuards';

import { UserContextComponent } from './context/User.context';

import {
  BrowserRouter,
  Routes,
  Route,
  withRouter,
  Navigate,
} from "react-router-dom";
import CustomToasters from './components/CustomToasters';

function App() {
  return (
    <BrowserRouter>
    <UserContextComponent>
      <CustomToasters/>
      <Routes>
        <Route exact path = '/'            element   = {<CentralGuard><Login/></CentralGuard>}/>
        <Route path       = '/brand'       element   = {<CentralGuard><Brand/></CentralGuard>}/>
        <Route path       = '/scanner'     element   = {<CentralGuard><CustomerPage/></CentralGuard>}/>
        <Route path       = '/flow'        element   = {<FlowData/>}/>
        <Route path       = '/readflows'   element   = {<ReadFlowData/>}/>
        <Route path       = '/deal'        element   = {<Deal/>}/>
        <Route path       = '/spend'       element   = {<Spend/>}/>
        <Route path       = "*"            element   = {<CentralGuard><Login/></CentralGuard>} />
      </Routes>
    </UserContextComponent>
    </BrowserRouter>
  );
}

export default App;
